/* .menu-icon {
    cursor: pointer;
    font-size: 30px;
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 1000;
  }
  
  .menu-list {
    position: fixed;
    top: 60px;
    right: 20px;
    background: white;
    border: 1px solid #ddd;
    padding: 10px;
    z-index: 1000;
  }
  
  .menu-list a {
    display: block;
    margin: 10px 0;
    color: black;
    text-decoration: none;
  }
   */

  .menuIcon {
    cursor: pointer;
    font-size: 45px; /* Increased from 30px to 1.5x for wider strokes */
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 1000;
  }
  
  /* Hover effect to show menu */
  .menuIcon:hover + .menuList,
  .menuList:hover { /* Keep the menu open when hovering over the menu itself */
    display: block;
  }
  
  .menuList {
    display: none; /* Hide by default, show on hover */
    position: fixed;
    top: 60px;
    right: 20px;
    background: white;
    border: 1px solid #ddd;
    padding: 10px;
    z-index: 1000;
  }
  
  .menuList a {
    display: block;
    margin: 10px 0;
    color: black;
    text-decoration: none;
  }

  .linkStyleButton {
    background: none;
    border: none;
    color: black;
    cursor: pointer;
    padding: 0;
    font: inherit;
}
  