.carousel-container {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
  }
  
.carousel-item {
    margin-right: 20px;
    flex: 0 0 auto; /* This ensures that the item doesn't grow or shrink */
    width: 300px; /* Adjust as needed */
}

.carousel-item img {
    width: 100%; /* Adjust as needed */
    height: auto;
}

.carousel-item p {
    text-align: center;
}

  