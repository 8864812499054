.contactPageContainer {
    background: url('../../resources/contactus.png') no-repeat center center fixed; 
    background-size: cover;
    padding: 50px;
    height: 100vh; /* Use full height to cover the entire viewport */
  }
  
  .contactForm {
    position: absolute;
    top: calc(50px + 20px); /* Original top position + space between fields */
    right: calc(100% - 4 * 300px); /* Assuming the form is 300px wide, adjust as needed */
    width: 300px; /* Adjust width as necessary */
  }
  
  .contactForm input,
  .contactForm textarea {
    width: 100%;
    margin-bottom: 20px;
    padding: 10px;
    border: none;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2); /* Slight shadow for depth */
  }
  
  .contactForm textarea.expanded {
    height: 300px; /* Larger height when expanded */
  }
  
  .contactForm button {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #000; /* Change as per your theme color */
    color: white;
    font-size: 16px;
    cursor: pointer;
  }
  
  .contactForm button:hover {
    background-color: #333; /* Darken button on hover */
  }
  
  /* Additional responsiveness and styling */
  @media (max-width: 768px) {
    .contactForm {
      width: 90%;
      right: 5%; /* Adjust to center on small screens */
      top: 20px;
    }
  }
  