/* Auth.module.css */
.authContainer {
    background: url('../../resources/artamural_signin_horizontal.png') no-repeat center center fixed;
    background-size: cover;
    padding: 50px;
    height: 100vh;
}

/* For narrow screens (e.g., mobile devices), use artamural_signin_vertical.png */
@media (max-width: 768px) {
    .authContainer {
        background: url('../../resources/artamural_signin_vertical.png') no-repeat center center fixed;
    }
}

.authForm {
    position: absolute;
    top: calc(50px + 20px);
    right: calc(100% - 4 * 300px);
    width: 300px;
}

.authInput,
.authTextarea {
    width: 100%;
    margin-bottom: 20px;
    padding: 10px;
    border: none;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
}

.authButton {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #000; /* Change as per your theme */
    color: white;
    font-size: 16px;
    cursor: pointer;
}

.authButton:hover {
    background-color: #333;
}

.googleSignInButton {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #000; /* Change as per your theme */
    color: white;
    font-size: 16px;
    cursor: pointer;
}

@media (max-width: 768px) {
    .authForm {
        width: 90%;
        right: 5%;
        top: 20px;
    }
}

.linkContainer {
    margin-top: 20px;
    text-align: center;
}

.authLink {
    /* Existing styles */
    display: block; /* Each link will be on a new line */
    text-align: center; /* Center the links */
    color: black; /* Keep your vivid color here */
    text-decoration: none; /* Removed underline, bold; */
    margin: 15px auto; /* Vertically space out the links, centered horizontally */
    padding: 10px 20px; /* Add more padding to create a larger clickable area */
    background-color: white; /* White background for the rectangle */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Shadow effect */
    border-radius: 5px; /* Optional: rounds the corners of the rectangle */
    transition: all 0.3s; /* Smooth transition for hover effects */
}

.authLink:hover {
    color: #003580; /* Darker shade when hovering over the link */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Slightly larger shadow on hover for effect */
    background-color: #f8f8f8; /* Slightly off-white background on hover for effect */
}

.buttonSpacer {
    margin-bottom: 20px;
}
