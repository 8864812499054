/* Default styles for mobile (vertical orientation) */
.home-container {
    height: 100vh;
    width: 100vw;
    background: url('../../resources/artamural_landing_vertical.png') no-repeat center center fixed;
    background-size: cover;
  }
  
  /* Styles for larger screens (horizontal orientation) */
  @media screen and (min-width: 768px) {
    .home-container {
      background: url('../../resources/artamural_landing_horizontal.png') no-repeat center center fixed;
    }
  }
  