.aboutPage {
    position: relative;
    text-align: center;
    color: white;
    background: url('../../resources/aboutbackground.png') no-repeat center center;
    background-size: cover;
  }
  
  .contentOverlay {
    padding: 4% 10%;
    background: rgba(0, 0, 0, 0.6); /* Adjust opacity as needed */
  }
  
  h1 {
    font-size: 2.5rem;
    margin-bottom: 2rem;
  }
  
  .valueProposition {
    font-size: 1.2rem;
    margin-bottom: 1rem;
    text-align: left;
  }
  
  .contactButton {
    font-weight: 600;
    margin-top: 2rem;
    padding: 1rem 2rem; /* Increase padding to make the button larger */
    background-color: #fff; /* White background */
    color: #000; /* Black text color */
    border: 1px solid #ddd; /* Subtle border */
    border-radius: 20px; /* Rounded corners */
    font-size: 1.2rem; /* Optional: Increase font size if needed */
    transition: background-color 0.3s ease;
  }
  
  .contactButton:hover {
    background-color: #eee; /* Light grey background on hover */
    color: #000; /* Text color remains black for readability */
  }
  
  
  
  /* Media queries for responsiveness */
  @media (max-width: 768px) {
    .aboutPage {
      padding: 50px 5%;
    }
  
    .valueProposition {
      font-size: 1rem;
    }
  
    h1 {
      font-size: 2rem;
    }
  }
  